type AsyncStorageKeys =
  | 'OnBoarding_NotificationPermission_Requested'
  | 'CacheAppVersion'
  | 'SelectedPhoneCountryCode'
  | 'BonusModal_IdVerification_shown'
  | 'CountBet'
  | 'HasLaunchedSuccessfully'
  | 'CheckNotiPopupStatus'
  | 'CacheLocalize'
  | 'UserInfo'
  | 'isRequestLocation'
  | 'isVisitWeb'
  | 'Verification'
  | 'Web_Version'
  | 'DisplayedModalBackDrop'
  | 'DisplayedModalBackDropTrial';

type AsyncStorageUserKeys =
  | 'OnBoardingFlow_Step'
  | 'OnBoardingFlow_Completed'
  | 'Authentication_LastConfirmedTime';

type AsyncStorageMatchStatsKeys = 'CacheMatchStats';

const AsyncStorageManager = {
  setItem: async (key: AsyncStorageKeys, value: string): Promise<void> => {
    await localStorage.setItem(key, value);
  },
  getItem: async (key: AsyncStorageKeys): Promise<string | null> => {
    return localStorage.getItem(key);
  },
  removeItem: async (key: AsyncStorageKeys): Promise<void> => {
    await localStorage.removeItem(key);
  },

  setItemForUser: async (
    userId: string,
    key: AsyncStorageUserKeys,
    value: string,
  ): Promise<void> => {
    await localStorage.setItem(`${userId}-${key}`, value);
  },
  getItemForUser: async (
    userId: string,
    key: AsyncStorageUserKeys,
  ): Promise<string | null> => {
    return localStorage.getItem(`${userId}-${key}`);
  },
  removeItemForUser: async (
    userId: string,
    key: AsyncStorageUserKeys,
  ): Promise<void> => {
    await localStorage.removeItem(`${userId}-${key}`);
  },
  setMatchStats: async (
    matchKeyString: string,
    key: AsyncStorageMatchStatsKeys,
  ): Promise<void> => {
    await localStorage.setItem(`${key}`, matchKeyString);
  },
  getMatchStats: async (
    key: AsyncStorageMatchStatsKeys,
  ): Promise<string | null> => {
    return localStorage.getItem(`${key}`);
  },
  removeMatchStats: async (key: AsyncStorageMatchStatsKeys): Promise<void> => {
    await localStorage.removeItem(`${key}`);
  },
};

export default AsyncStorageManager;
